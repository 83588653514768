import { css } from '@emotion/react';
import { Prism } from '@mantine/prism';
import dedent from 'dedent';
import { memo } from 'react';
export const DemoCode = memo(function Demo_Code(props) {
    const valueString = 'json' in props
        ? !props.json
            ? 'undefined'
            : JSON.stringify(props.json, null, 2)
        : dedent(props.children);
    return (<Prism language='tsx' copyLabel='複制代碼' copiedLabel='已複制代碼' css={css `
          .mantine-Prism-lineContent {
            font-size: 16px;
          }
        `}>
        {valueString}
      </Prism>);
});
