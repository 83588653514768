import { HubConnectionState } from '@microsoft/signalr';
import { useCallback, useEffect } from 'react';
import { useInterval, useMount, useUpdateEffect } from 'react-use';
import { apirc } from '~/configs/apirc';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { eventEmitter } from '~/modules/SDK/Events/eventEmitter';
import { EventString } from '~/modules/SDK/Events/EventString';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useSignalrStoreValueTradeInfo_proxy } from '~/modules/SDK/Signalr/useSignalrStoreValueTradeInfo';
/**
 * 建立 Signalr 連線
 *
 * 可取得 OHLC 報價，包含台指期、海期、選擇權的值
 *
 * @example <caption>OHLC 報價</caption>
 *   const Page: React.FC = props => {
 *     useSignalrBuild({ renderInterval: 500 })
 *
 *     return <SignalrQuote symbol='GC-1'></SignalrQuote>
 *   }
 */
export const useSignalrBuild = (options) => {
    const autoReConnectInterval = options?.reconnectCheckInterval || 30000;
    const valueBuilder = options?.valueBuilder;
    const uid = useMeStore(state => state.meUserState?.uid);
    const handleToCache = useCallback(() => {
        // 報價資料暫存在 hook 本身，而非 store，配合 renderInterval 參數，避免 UI 過度性能消耗
        useSignalrStore
            .getState()
            .connection?.on(apirc.signalr.event.Quote, (data) => {
            debugAPI.signalrQuote.logIf(() => 'close' in data, `signal.on(Quote)`, data);
            if (data.symbol === 'TX-1') {
                debugAPI.symbol_TX1.log('signal.on(Quote)', data);
            }
            if (valueBuilder) {
                valueBuilder?.(data);
            }
            else {
                useSignalrStoreValueOHLC.getState().valueBuilder(data);
            }
        });
        // 報價資料暫存在 hook 本身，而非 store，配合 renderInterval 參數，避免 UI 過度性能消耗
        useSignalrStore
            .getState()
            .connection?.on(apirc.signalr.event.TradeInfo, (data) => {
            useSignalrStoreValueTradeInfo_proxy.valueBuilder(data);
        });
    }, [valueBuilder]);
    // initial
    useMount(() => {
        useSignalrStore.getState().start().then(handleToCache);
    });
    // 若前一個連線沒有 state.uid，但到了此階段已有了 state.uid；那麼帶上 state.uid 重新連線吧
    useUpdateEffect(() => {
        useSignalrStore.getState().restart().then(handleToCache);
    }, [uid]);
    // 重新連線檢查機制
    useInterval(() => {
        const connectState = useSignalrStore.getState().connection?.state || HubConnectionState.Disconnected;
        if (connectState === HubConnectionState.Disconnected) {
            useSignalrStore.getState().start().then(handleToCache);
        }
    }, autoReConnectInterval);
    // 當手機休眠後喚醒，接收 browser 的 visibilityChange 事件，來進行重新連線，以避免 UI 報價不正確重新開始 render
    useEffect(() => {
        const callback = (data) => {
            if (data.state === 'visible' &&
                useSignalrStore.getState().connection?.state === HubConnectionState.Disconnected) {
                useSignalrStore.getState().start().then(handleToCache);
            }
        };
        eventEmitter.on(EventString.visibilityChange, callback);
        return () => {
            eventEmitter.off(EventString.visibilityChange, callback);
        };
    });
};
