import { css } from '@emotion/react';
import { Button, Popover } from '@mantine/core';
import { isError } from 'lodash';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FcLike } from 'react-icons/fc';
import { GrDislike } from 'react-icons/gr';
import { useMount } from 'react-use';
import { DemoCode } from '~/components/DemoCode';
import { Preset_EntirePage } from '~/pages/heineken_template/_preset/preset_EntirePage';
import { component } from '~/utils/component';
import { FrGa } from '~/modules/SDK/FrGa/FrGa';
export const _PopoverCallStackSaySorry = component(props => {
    return <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>;
});
const Fallback = component(props => {
    const error = isError(props.error) ? props.error : undefined;
    const [opened, setOpened] = useState(true);
    useMount(() => {
        FrGa.event({
            前端運行時錯誤: {
                message: error?.message || '沒有發現任何錯誤訊息',
            },
        });
    });
    return (<Preset_EntirePage css={css `
        display: flex;
        place-content: center;
        flex-direction: column;
        padding: 0 10%;
      `}>
      <Popover opened={opened}>
        <Popover.Target>
          <div>
            <Button color='gray' onClick={event => {
            setOpened(false);
        }}>
              關閉提示框
            </Button>
            <Button color='green' onClick={event => {
            props.resetErrorBoundary();
        }}>
              重試一次
            </Button>
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <div>非常抱歉，此區塊崩潰了，「請截圖並聯絡我們的客服」，我們會立即為您處理！</div>
          <div>
            <DemoCode>{`${error?.stack}`}</DemoCode>
          </div>

          <div css={css `
              display: flex;
              justify-content: flex-end;
              gap: 8px;

              & > ._ICON {
                background: white;
                border-radius: 50px;
                padding: 8px;
                cursor: pointer;
                font-size: 3rem;

                &:active {
                  transform: translate(0, 6px);
                }
              }
            `}>
            <GrDislike className='_ICON' onClick={event => {
            FrGa.event({ 前端運行時錯誤_點擊__倒讚: {} });
        }}></GrDislike>

            <FcLike className='_ICON' onClick={event => {
            FrGa.event({ 前端運行時錯誤_點擊__愛心: {} });
        }}></FcLike>
          </div>
        </Popover.Dropdown>
      </Popover>
    </Preset_EntirePage>);
});
